import React from 'react'
import { useState, useEffect } from "react"
import DiscController2 from "./components/Zone2";
import DiscController1 from "./components/zone1";
import DiscController3 from "./components/zone3";
import DiscController4 from "./components/zone4";
import time from "./components/Updatetime";


async function Mount() {

  const tsk = window.__RUNTIME_CONFIG__.REACT_TSK
  var requestOptions = {
    method: 'GET',
    // mode: "no-cors",
    mode: 'cors',
    headers: {
      // 'Access-Control-Allow-Origin':'*'
      'ebobo':tsk
    },
    redirect: 'follow'
  };
  //from url string ?
  const queryParameters = new URLSearchParams(window.location.search)
  const payloadUrl = queryParameters.get("payload")
  const payloadId = queryParameters.get("id")
  // const { REACT_APP_API_URL } = process.env
  let url = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

  if (payloadUrl != undefined){
    url = payloadUrl
    console.log("--->Taking over by Url:",url);
  }else if(payloadId != undefined){
    // const pieces = url.split(/[\s,]+/)
    const pieces = url.split('/')
    const currentId = pieces[pieces.length - 1]
    console.log("--->Taking over by Id:",url,",current Id:",currentId);
    url = url.replace(currentId,payloadId)
    console.log("--->Taking over by Id:",url);
  }
  console.log("Calling it ok:",url);
  let response= {};
  let fileJSON={};
  try{
    response = await fetch(url, requestOptions);  // waits until the request completes...
    fileJSON = await response.json();
    console.log("Calling Done:",url);
    var decodedCanvas = Buffer.from(fileJSON.canvas, 'base64');
    var jsonstring = decodedCanvas.toString()
    fileJSON = JSON.parse(jsonstring)
    return fileJSON
  }catch( error)
  {
    console.error("Fetch error:", error)
  }

  return {}

} // end mount

function Connection() {
  const [fileJSON, setfileJSON] = useState([])
  // first Mount each time the App component is loaded
  useEffect(() => {
    Mount().then(result => setfileJSON(result))
  }, [])

  // Periodic Mount 
  const [NewfileJSON, setNewfileJSON] = useState([])
  useEffect(() => {

    let timer = setTimeout(() => {
      Mount().then(result => {
        setNewfileJSON(result)
        //console.log("different", JSON.stringify(result) !== JSON.stringify(fileJSON))
        //console.log("donnée comparée", result)
        if (JSON.stringify(result) !== JSON.stringify(fileJSON)) {
          setfileJSON(result)
        }
      })
    }, time) //  times for to update fileJSON on the Proxy 
    return () => clearTimeout(timer)
  })

  /*if (fileJSON.length !== 0) {
    console.log("donnée envoyée pour l'affichage ", fileJSON);
  }*/
  // don't delete. it just to solve this warning :  'NewfileJSON' is assigned a value but never used  no-unused-vars
  if (0 > 2) {
    console.log(NewfileJSON)
  }
  console.log("what::",fileJSON);
  if (window.matchMedia("(orientation: landscape)").matches) {
    return (fileJSON.length !== 0 ? (
      <div className=" vh-100 py-1 container-fluid ">
        <div className="  h-75 row px-1 ">
        <DiscController1 file1={fileJSON} />
        </div>
        <div className=" row  h-25 px-1 pt-1" >
          <DiscController2 file2={fileJSON}  />
          <DiscController3 file3={fileJSON}  />
          <DiscController4 file4={fileJSON}  />
        </div>
      </div >
    ) : (""))
  }else{
    return (fileJSON.length !== 0 ? (
      <div className=" vh-100 py-1 container-fluid ">
        <div className="  h-75 row px-1 ">
        <DiscController1 file1={fileJSON} />
        </div>
        <div className=" row  h-25 px-1 pt-1" >
          <DiscController2 file2={fileJSON}  />
          <DiscController3 file3={fileJSON}  />
          <DiscController4 file4={fileJSON}  />
        </div>
      </div >
    ) : (""))
  }

}// end connection


function App() {

  return <Connection/>
}

export default App;
