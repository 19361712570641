import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
//import { useState, useEffect } from "react";
import Style from './Style'
import URL from './Defaultimage';


//set disc playback end time
function DiscController2(props) {

    // filtrer la zone 2 
    var data = props.file2.slots.filter(payload => {
        return (payload.zone === Number("2") ? payload : null)
    });

    //filter out passed showing time
    var filteredData = data.filter(payload => {
        return (payload.end_timestamp > Math.round(Date.now() / 1000) ? payload : null)
    });

    data= filteredData;

    // le cas où data est vide on renvoi la page par défaut
    if (data.length === 0) {
        return (
            // fix default page of toolgo
            <div className="col mx-1 px-0 h-100 w-100" style={{ overflow: 'hidden' }}>
                <Carousel fade pause={false} controls={false} indicators = {false} >
                    <Carousel.Item key={1} >
                        <img
                            className="d-block "
                            src= {URL}
                            alt="par defaut end"
                            style={Style}
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
    //trier par plus petit start_timestamp
    data.sort((a, b) => (a.start_timestamp > b.start_timestamp ? 1 : -1))
    
    const restTimes = data[data.length - 1].end_timestamp - Math.round(Date.now() / 1000)
    //console.log(data, "resttime", restTimes, "zone 2")


    return (restTimes <= 0 ? (
        // payload is an old payload
        // fix default page of toolgo
        <div className="col h-100 w-100 px-0  mx-1" style={{ overflow: 'hidden' }}>
            <Carousel fade pause={false} controls={false} indicators = {false} >
                <Carousel.Item key={1} >
                    <img
                        className="d-block "
                        src= {URL}
                        alt="par defaut end"
                        style={Style}
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    ) :
        (
            // payload is a new payload
            < DiscCarousel2 data1={data} />
        ))

} // end DiscController2



//create disc tray which will be display
function DiscCarousel2(props) {

    const data1 = props.data1
    // durée de  <Carousel.Item key={11} interval={2 * 86400 }>  est de 2 jours 
    return (
        <div className="col mx-1 px-0 h-100 w-100" style={{ overflow: 'hidden' }}>
            <Carousel fade pause={false} controls={false} indicators = {false}>
                {data1.map((slides) => (
                    DiscCarouselItem2(slides)
                ))}
                <Carousel.Item key={11} interval={2 * 86400}>
                    <img
                        className="d-block "
                        src= {URL}
                        alt="par defaut end"
                        style={Style}
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    );
} // end DiscCarousel2

//set disc playback start time
function DiscCarouselItem2(slotone) {
    var move=true
    var restTimes = slotone.start_timestamp - Math.round(Date.now() / 1000)
    if(restTimes <= 0){
        move=false
    }

    return (move ?
        (
            <Carousel.Item key={slotone.id} interval={(slotone.end_timestamp - slotone.start_timestamp) * 1000} >
                <img
                    className="d-block "
                    src={slotone.media_src}
                    alt={slotone.client}
                    style={Style}
                />
            </Carousel.Item>
        ) :
        (
            // fix a default page of toolgo 
            <Carousel.Item key={slotone.id + Date.now()} interval={(slotone.start_timestamp - Math.round(Date.now() / 1000) + (slotone.end_timestamp - slotone.start_timestamp)) * 1000} >
                <img
                    className="d-block "
                    src= {URL}
                    alt="par defaut wait "
                    style={Style}
                />
            </Carousel.Item>
        )
    )
} // end DiscCarouselItem2



export default DiscController2
