var screenHeight="";
var screenWidth="";
var Style ="";
if (window.matchMedia("(orientation: portrait)").matches) {
  screenHeight=window.screen.height/4;
  screenWidth="100%";
}
if (window.matchMedia("(orientation: landscape)").matches) {
  //screenHeight=window.screen.height/2;
  screenHeight=window.screen.height/4; 
  screenWidth="100%";
}


//const adjustable_Height= window.screen.height/2 
Style = {
  width: screenWidth,
  height: screenHeight,
  position: 'static'
};

export default Style