var screenHeight="";
var Style ="";
if (window.matchMedia("(orientation: portrait)").matches) {
  screenHeight=(window.screen.height/4)*3;
  //screenHeight="60%";
}
if (window.matchMedia("(orientation: landscape)").matches) {
  //screenHeight=window.screen.height/2;
  screenHeight=(window.screen.height/4)*3; 
}

//const adjustable_Height= window.screen.height/2 
Style = {
  width: '100%',
  height: screenHeight,
  position: 'static'
};

//const adjustable_Height= window.screen.height/2 


export default Style